import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const animationProps = {
  "data-sal": "slide-up",
  "data-sal-duration": "900",
  "data-sal-easing": "ease",
}

export function Intro({ hero, sub }) {
  return (
    <section className="flex flex-col justify-center min-h-screen mx-auto bg-anti-flash-white sm:min-h-1/2-screen">
      <h1 className="container px-4 py-20 mx-auto font-semibold leading-tight text-charleston-green font-display">
        <span className="block text-4xl sm:text-5xl" {...animationProps}>
          {hero}
        </span>
        <span
          className="block mt-8 text-3xl text-feldgrau sm:text-4xl sm:mt-12"
          {...animationProps}
        >
          {sub}
        </span>
      </h1>
    </section>
  )
}

export default Intro
