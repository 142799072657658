import React from "react"

const animationProps = {
  "data-sal": "slide-up",
  "data-sal-duration": "700",
  "data-sal-easing": "ease",
}

function Services({ items }) {
  return (
    <section className="text-gray-200 bg-feldgrau sm:py-6">
      <div className="container p-4 mx-auto">
        <h2 className="inline-block pb-2 my-2 text-2xl font-medium border-b border-white font-display">
          Services
        </h2>
        <div className="sm:flex sm:items-start sm:-mx-2">
          {items.map((service, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`service-${i}`}
              className="flex flex-col items-center justify-center w-full my-10 sm:p-4 sm:my-6"
              {...animationProps}
            >
              <img
                src={service.icon.url}
                alt="Lawn Care Services"
                className="h-20"
              />
              <h3 className="mt-2 text-xl font-semibold font-display">
                {service.heading.text}
              </h3>
              <div
                className="w-full"
                dangerouslySetInnerHTML={{
                  __html: service.text.html.replace(
                    /<p>/gi,
                    '<p class="my-6 text-center">'
                  ),
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Services
