import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Image from "../components/image"
import Intro from "../components/intro"
import Layout from "../components/layout"
import Logo from "../images/logo_dark.svg"
import React from "react"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import { graphql } from "gatsby"
import Services from "../components/services"
import TelephoneCta from "../components/telephoneCta"
import ContentSection from "../components/contentSecion"
import SEO from "../components/seo"

const animationProps = {
  "data-sal": "slide-up",
  "data-sal-duration": "700",
  "data-sal-easing": "ease",
}

function isEven(n) {
  return n % 2 === 0
}

function renderSlice(slice, i) {
  switch (slice.slice_type) {
    case "services":
      return <Services key={`slice-${i}`} {...slice} />
    case "contact_cta":
      return <TelephoneCta key={`slice-${i}`} {...slice} />
    case "content":
      return (
        <ContentSection
          key={`slice-${i}`}
          {...slice}
          position={isEven(i) ? "left" : "right"}
        />
      )
    default:
      return null
  }
}

function IndexPage({
  data: {
    prismicMainPage: { data },
  },
}) {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="relative w-full h-screen overflow-hidden min-h-64 sm:h-screen">
        <Image image={data.hero_image.fluid} />
        <div className="absolute inset-0 z-20 flex flex-col items-center text-white">
          <img
            src={Logo}
            alt="Meticulous Gardens"
            className="h-32 mt-auto sm:h-48"
          />
          <div
            className="flex flex-col items-center mt-auto opacity-75"
            {...animationProps}
            data-sal="slide-down"
            data-sal-delay="1000"
          >
            <span className="text-xs">Scroll Down</span>
            <FontAwesomeIcon icon={faArrowDown} className="mt-2 mb-4" />
          </div>
        </div>
        <div className="absolute inset-0 z-10 bg-gray-900 opacity-75" />
      </div>
      <Intro hero={data.hero_line.text} sub={data.hero_subline.text} />
      {data.body.map(renderSlice)}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    prismicMainPage {
      data {
        hero_image {
          fluid(maxWidth: 2000) {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_line {
          text
        }
        hero_subline {
          text
        }
        body {
          __typename
          ... on PrismicMainPageBodyServices {
            slice_type
            items {
              icon {
                url
              }
              heading {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicMainPageBodyContactCta {
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicMainPageBodyContent {
            slice_type
            primary {
              theme
              heading {
                text
              }
              text {
                html
              }
              image {
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
