import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"

const animationProps = {
  "data-sal": "slide-up",
  "data-sal-duration": "700",
  "data-sal-easing": "ease",
}

export function TelephoneCta({ primary: { heading, text } }) {
  return (
    <section className="py-10 text-gray-200 bg-anti-flash-white text-charleston-green sm:py-16">
      <div className="container p-4 mx-auto">
        <h2 className="inline-block pb-2 mb-2 text-2xl font-medium border-b border-charleston-green font-display">
          {heading.text}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: text.html.replace(/<p>/gi, '<p class="my-6">'),
          }}
        />
        <div className="text-center">
          <a
            href="tel:+447880233200"
            className="flex items-center justify-center w-full px-8 py-4 mt-10 text-white rounded-full bg-forest-green sm:inline-flex sm:w-auto"
            {...animationProps}
          >
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
            Call now{" "}
            <span className="hidden sm:inline">
              &nbsp;on <strong>07880 233 200</strong>
            </span>
          </a>
          <div className="mt-6 text-sm text-gray-600">
            Alternatively you can email us on{" "}
            <a
              href="mailto:info@meticulousgardens.com"
              className="text-blue-600"
            >
              info@meticulousgardens.com
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
export default TelephoneCta
