import React from "react"
import Image from "gatsby-image"
import classnames from "classnames"

const themes = {
  "charleston-green": {
    section: "bg-charleston-green text-anti-flash-white",
    header: "border-anti-flash-white",
  },
  "forest-green": {
    section: "bg-forest-green text-anti-flash-white",
    header: "border-anti-flash-white",
  },
  feldgrau: {
    section: "bg-feldgrau text-anti-flash-white",
    header: "border-anti-flash-white",
  },
  "anti-flash-white": {
    section: "bg-anti-flash-white text-charleston-green",
    header: "border-charleston-green",
  },
}

export function ContentSection({
  position = "left",
  primary: { heading, text, theme, image },
}) {
  const themeClasses = themes[theme]
  return (
    <section className={`${themeClasses.section}`}>
      <div
        className={classnames("container mx-auto sm:flex", {
          "sm:flex-row-reverse": position === "right",
        })}
      >
        <div className="relative w-full sm:w-1/2 min-h-1/2-screen">
          {image && (
            <Image
              className="absolute inset-0"
              fluid={image.fluid}
            />
          )}
        </div>
        <div
          className={classnames("p-4 sm:p-10 sm:w-1/2", {
            "sm:pr-4": position === "left",
            "sm:pl-4": position === "right",
          })}
        >
          <h2
            className={`inline-block pb-2 my-2 text-2xl font-medium border-b font-display ${themeClasses.header}`}
          >
            {heading.text}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: text.html.replace(/<p>/gi, '<p class="my-6">'),
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default ContentSection
